import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { dimensionNuance: Array }
  static targets = ["chartGeneralExperience"]

  connect() {
    this._displayChartGeneralExperience();
  }

  _displayChartGeneralExperience() {
    const chart = new ApexCharts(this.chartGeneralExperienceTarget, this._compileDataForChart());
    chart.render();
  }

  _compileSeriesData() {
    const series = []
    let insert_data_at_index = 0
    let number_of_iteration = 0
    this.dimensionNuanceValue.forEach(nuance_data => {
      const array_value_nuance = new Array()
      array_value_nuance[insert_data_at_index] = nuance_data.data
      let feeling = number_of_iteration % 2 === 1 ? feeling = "négatif" : feeling = "positif"
      series.push({ name: feeling, data: array_value_nuance })

      number_of_iteration % 2 === 1 ? insert_data_at_index += 1 : insert_data_at_index
      number_of_iteration += 1
    })
    return series
  }

  _compileDataForChart() {
    const options = {
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,
        formatter: function (val) {
          if (val > 0) return val + "%";
        },
      },
      grid: {
        show: false,
        padding: {
          left: 20,
          right: 30
        }
      },
      colors: [
        // colere vs calm
        "#F14131", "#F66452",
        // Joie vs tristesse
        "#F8752A", "#FC9E59",
        // Corporel vs Stress
        "#F1B048", "#F6CF84",
        // Perception de soi
        "#42684F", "#5E856A",
        // Face à l'inconnu
        "#6A7E72", "#95B1A0",
        // Emotion relationnelle
        "#365240", "#516658",
        // Envie vs dégout
        "#E94F1A", "#F16730"
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ["Calme et colère", "Joie et tristesse", "Corporel et stress", "Perception de soi", "Face à l'inconnu", "Emotion relationnelle", "Envie et dégoût"],
        min: 0,
        max: 100,
        tickAmount: 1,
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%"
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'left',
        offsetX: 0,
      },
    };
    options.series = this._compileSeriesData()
    return options
  }
}
